import React from "react";

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section id="info-utah" className="about-us pt-trip gray-light-bg">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <div className="section-heading text-center mb-5">
                    {this.props.showTitle && this.props.showTitle === true && (
                      <title className="badge badge-primary badge-pill">
                        Utah National Parks Road Trip
                      </title>
                     )}
                    <h1>
                      Utah National Parks Road Trip 5 Days<br />
                    </h1>
                  <p>
                    Planning a long weekend? Consider a 5-day road trip to Utah's national parks. Utah has five amazing parks: Zion, Bryce Canyon, Capitol Reef, Arches, and Canyonlands. This itinerary will help you drive through beautiful canyons, over tall mountains, and across open deserts. To see a full Utah National Parks itinerary, <strong>click "more options" on Google Maps below</strong>.</p>

                  <p> While a week would be great to explore, you can enjoy a wonderful trip in just 5 days!
<strong> The best times to visit are usually in spring (April to June) and fall (September to October)</strong>. The weather is mild during these months, and the parks have fewer crowds.
                  </p>
                  </div>
                </div>
              </div>
            </div>
        </section>
    </React.Fragment>
  );
  }
}
export default About;