import React from 'react';
import _data from '../../data';
import { SRLWrapper } from 'simple-react-lightbox';
import SimpleReactLightbox from 'simple-react-lightbox';

export default class Pricing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      price: {},
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

    this.setState({
      price: _data.price,
    });
  }

  renderUniqueContent(sectionIndex) {
    const contents = [
      {
        title: "Day 2 Bryce Canyon National Park",
        description: "Kick off your day with a hike on the iconic Queens Garden & Navajo Loop Trail. To get to Sunrise Point, hop on Bryce Canyon’s free shuttle, running from mid-April to mid-October—it'll drop you off right where you need to be. After a day of exploring, wind down by soaking in the mineral-rich waters of the stunning Mystic Hot Springs. Perfect way to end the day!"
      },
      {
        title: "Day 3 Capitol Reef National Park",
        description: "Start your Capitol Reef day by hiking the Hickman Bridge Trail, a moderate trek to a stunning natural bridge. For a bigger challenge, tackle the Cassidy Arch Trail for jaw-dropping views. Afterward, cruise along the Scenic Drive and stop by Fruita’s historic orchards, where you can pick fruit in season (typically from June to October). The orchards are open daily from 9 AM to 5 PM, and fruit is just $1 per pound."
      },
      {
        title: "Day 4 Arches National Park",
        description: "Start your day with a quick stop at Spotted Wolf Canyon Viewpoint, the perfect scenic spot for some amazing photos of the surrounding landscape. Next up, for the adventure seekers, the Fisher Towers Rafting Trip is a must-do! Spend about 6 hours rafting along a 14-mile route that winds through Onion Creek, Rocky Rapid, and the thrilling Whites Rapid. Don’t forget to pack some snacks for the ride! End the day with a hike to the iconic Delicate Arch, a 3-mile round-trip that leads you to Utah’s most famous and photogenic arch. Perfect for catching the sunset!"
      },
      {
        title: "Day 5 Moab & Canyonlands & Horseshoe Bend",
        description: "Kick off the day with the T-Rex 4x4 Tour, a thrilling 3-hour drive-yourself off-road experience, starting at either 8:15 AM or 12:15 PM. For a more serene adventure, take a short 0.5-mile hike to Mesa Arch, a popular sunrise spot with stunning views over the canyon. Finish the day with a visit to Horseshoe Bend—a must-see destination near Page, Arizona. The 1.5-mile hike brings you to a breathtaking view of the Colorado River’s famous horseshoe curve. Perfect for sunset photos, just note that a parking fee applies!"
      }
    ];

    const content = contents[sectionIndex % contents.length];

    return (
      <>
        <h2 className="text-center mt-5 mb-4">{content.title}</h2>
        <p className="text-center mb-5">{content.description}</p>
      </>
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className="overflow-hidden">
          <section
            id="utah-pricing"
            className={
              'package-section ptb-55 ' +
              (this.props.bgColor && this.props.bgColor === 'gray'
                ? 'gray-light-bg'
                : 'background-shape-right')
            }
          >
            <div className="container">
              <h2 className="text-center mb-4">Day 1 Zion National Park</h2>
              <p className="text-center mb-5">
               Start your day early to beat the heat and crowds! Zion’s free shuttle makes it super easy to get around, so no need to worry about tickets. If you aim to hike Angels Landing, <a target='_blanc' className='intext-link' href="https://www.nps.gov/zion/planyourvisit/angels-landing-hiking-permits.htm">snag a permit</a>. No permit? No problem! Check out the Zion Overlook Trail or wade through The Narrows—plenty to fill your day. Just remember to <a target='_blanc' className='intext-link' href="https://www.zionguru.com/narrows-rental-equipment">gear up with the right boots and outfit</a> for The Narrows hike!
              </p>
              <div className="row justify-content-center">
                {(this.state.price.utahPackages || []).map((_package, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="col-lg-4 col-md">
                        <div
                          className={
                            'card text-center single-pricing-pack ' +
                            (_package.isFeatured ? 'popular-price' : '')
                          }
                        >
                          <div className="pt-4">
                            <h3>{_package.license}</h3>
                          </div>
                          <div className="pricing-img mt-4">
                            <img
                              src={_package.photoImg}
                              alt={_package.photoImg}
                              className="img-fluid"
                            />
                          </div>
                          <div className="card-header py-4 border-0 pricing-header">
                            <div className="h1 text-center mb-0">
                              <span className="price font-weight-bolder">
                                {_package.price}
                              </span>
                            </div>
                          </div>
                          <div className="card-body">
                            <ul className="list-unstyled text-sm mb-4 pricing-feature-list">
                              {(_package.features || []).map((feature) => {
                                return <li key={feature}>{feature}</li>;
                              })}
                            </ul>

                            <a
                              href={_package.fareharborURL}
                              className={
                                'btn mb-3 ' +
                                (_package.isFeatured
                                  ? 'trip-solid-btn'
                                  : 'trip-outline-btn')
                              }
                            >
                            </a>
                          </div>
                        </div>
                      </div>
                      {(index + 1) % 3 === 0 && index !== this.state.price.utahPackages.length - 1 && (
                        <div className="col-12">
                          {this.renderUniqueContent(Math.floor(index / 3))}
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>

              {!(this.props.hideFaq && this.props.hideFaq === true) &&
                this.props.theme !== 'four' && (
                  <div className="row mt-5">
                    <div className="col-lg-12">
                      <h2 className="text-center mb-4">Utah National Parks FAQs</h2>
                      <div id="accordion-1" className="accordion accordion-faq">
                        <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-1-1"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-1-1"
                            aria-expanded="false"
                            aria-controls="collapse-1-1"
                          >
                            <h3 className="mb-0">
                              <span className="ti-receipt mr-3"></span> When is the best time to visit Utah National Parks?
                            </h3>
                          </div>
                          <div
                            id="collapse-1-1"
                            className="collapse"
                            aria-labelledby="heading-1-1"
                            data-parent="#accordion-1"
                          >
                            <div className="card-body">
                              <p>
                                <strong>April–May</strong>, and <strong>September–October</strong> is the perfect time for a road trip through Utah National Parks. Compared to other times of the year, which are either too hot or too cold for hiking and outdoor activities, these four months are perfect weatherwise and less crowded. 
                              <br />
                                P.S. If you want to explore Utah with our team, shoot us a message on <a target='_blanc' className='intext-link' href="https://www.instagram.com/exploroff/">IG</a>!
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-1-2"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-1-2"
                            aria-expanded="false"
                            aria-controls="collapse-1-2"
                          >
                            <h3 className="mb-0">
                              <span className="ti-gallery mr-3"></span> Where to stay while exploring the rim of Utah's national parks?
                            </h3>
                          </div>
                          <div
                            id="collapse-1-2"
                            className="collapse"
                            aria-labelledby="heading-1-2"
                            data-parent="#accordion-1"
                          >
                            <div className="card-body">
                              <p>
                                As a true explorer, you may want to stay in a tent, cabin, or even Teepee. Check out 
                               <a target='_blanc' className='intext-link' href="https://www.recreation.gov/"> recreation.gov</a> and 
                               <a target='_blanc' className='intext-link' href="https://www.hipcamp.com/"> hipcamp.com</a>.
                                <br />
                                P.S. Sometimes, you can contact hosts on Hipcamp directly and ask to get notified if the dates you are looking for are currently booked. 
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-1-3"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-1-3"
                            aria-expanded="false"
                            aria-controls="collapse-1-3"
                          >
                            <h3 className="mb-0">
                              <span className="ti-wallet mr-3"></span> How far in advance should I plan my weekend getaway in Utah?
                            </h3>
                          </div>
                          <div
                            id="collapse-1-3"
                            className="collapse"
                            aria-labelledby="heading-1-3"
                            data-parent="#accordion-1"
                          >
                            <div className="card-body">
                              <p>
                                We recommend booking your Utah adventure at least a few months in advance. Check for CDC guidelines updates regularly on the websites of the National Parks you plan on visiting. 
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-1-4"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-1-4"
                            aria-expanded="false"
                            aria-controls="collapse-1-4"
                          >
                            <h3 className="mb-0">
                              <span className="ti-receipt mr-3"></span> How do I book everything last minute?
                            </h3>
                          </div>
                          <div
                            id="collapse-1-4"
                            className="collapse"
                            aria-labelledby="heading-1-4"
                            data-parent="#accordion-1"
                          >
                            <div className="card-body">
                              <p>
                                If you decide to visit Utah on short notice, we can plan this adventure for you! Please fill out your <strong>Utah Getaway Inquiry</strong>, and we will get back to you within 24 hrs of your request!
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-1-5"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-1-5"
                            aria-expanded="false"
                            aria-controls="collapse-1-5"
                          >
                            <h3 className="mb-0">
                              <span className="ti-lock mr-3"></span> Do you need permits to hike in Utah?
                            </h3>
                          </div>
                          <div
                            id="collapse-1-5"
                            className="collapse"
                            aria-labelledby="heading-1-5"
                            data-parent="#accordion-1"
                          >
                            <div className="card-body">
                              <p>
                            The activities listed above don't require permits. Check for <a target="_blanc" className='intext-link' href="https://www.blm.gov/programs/recreation/passes-and-permits/lotteries-and-permit-systems/utah">exclusive hikes and lottery-based permits</a>.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-1-6"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-1-6"
                            aria-expanded="false"
                            aria-controls="collapse-1-6"
                          >
                            <h3 className="mb-0">
                              <span className="ti-receipt mr-3"></span> How many days do you need to see Utah National Parks?
                            </h3>
                          </div>
                          <div
                            id="collapse-1-6"
                            className="collapse"
                            aria-labelledby="heading-1-6"
                            data-parent="#accordion-1"
                          >
                            <div className="card-body">
                              <p>
                                We highly recommend spending an entire week exploring Utah National Parks. It is however possible to fit your Utah adventure into a long weekend if you pre-plan your trip.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-1-7"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-1-7"
                            aria-expanded="false"
                            aria-controls="collapse-1-7"
                          >
                            <h3 className="mb-0">
                              <span className="ti-receipt mr-3"></span> What is the most beautiful park in Utah?
                            </h3>
                          </div>
                          <div
                            id="collapse-1-7"
                            className="collapse"
                            aria-labelledby="heading-1-7"
                            data-parent="#accordion-1"
                          >
                            <div className="card-body">
                              <p>
                                Zion is the most beautiful park in Utah. If you have a limited time we recommend you hike Angel's Landing and Narrows trails.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-1-8"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-1-8"
                            aria-expanded="false"
                            aria-controls="collapse-1-8"
                          >
                            <h3 className="mb-0 d-flex align-items-center justify-content-between">
                              <span>
                                <span className="ti-location-pin mr-3"></span>
                                What is the closest airport to Zion National Park?
                              </span>
                              <span className="faq-toggle-icon">
                                <i className="fa fa-plus"></i>
                                <i className="fa fa-minus"></i>
                              </span>
                            </h3>
                          </div>
                          <div id="collapse-1-8" className="collapse" aria-labelledby="heading-1-8" data-parent="#accordion-1">
                            <div className="card-body">
                              <p>
                                The closest major airport to Zion National Park is St. George Regional Airport (SGU), located about 50 miles away. It's a small regional airport with flights connecting to larger hubs like Salt Lake City, Denver, and Phoenix. For international travelers, McCarran International Airport (LAS) in Las Vegas, Nevada, is about 160 miles from the park and offers a wider range of flight options.
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-1-10"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-1-10"
                            aria-expanded="false"
                            aria-controls="collapse-1-10"
                          >
                            <h3 className="mb-0 d-flex align-items-center justify-content-between">
                              <span>
                                <span className="ti-car mr-3"></span>
                                How do I get from Bryce Canyon to Zion National Park?
                              </span>
                              <span className="faq-toggle-icon">
                                <i className="fa fa-plus"></i>
                                <i className="fa fa-minus"></i>
                              </span>
                            </h3>
                          </div>
                          <div id="collapse-1-10" className="collapse" aria-labelledby="heading-1-10" data-parent="#accordion-1">
                            <div className="card-body">
                              <p>
                                To travel from Bryce Canyon to Zion National Park, take Highway 89 south and then head west on Highway 9 through the Zion-Mount Carmel Tunnel. The drive is about 72 miles and typically takes around 1.5 to 2 hours. It's a scenic route with plenty of opportunities to stop and take in the views along the way.
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-1-11"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-1-11"
                            aria-expanded="false"
                            aria-controls="collapse-1-11"
                          >
                            <h3 className="mb-0 d-flex align-items-center justify-content-between">
                              <span>
                                <span className="ti-time mr-3"></span>
                                How long does it take to drive from St. George, Utah, to Zion National Park?
                              </span>
                              <span className="faq-toggle-icon">
                                <i className="fa fa-plus"></i>
                                <i className="fa fa-minus"></i>
                              </span>
                            </h3>
                          </div>
                          <div id="collapse-1-11" className="collapse" aria-labelledby="heading-1-11" data-parent="#accordion-1">
                            <div className="card-body">
                              <p>
                                The drive from St. George, Utah, to Zion National Park is approximately 50 miles and takes about 1 hour. The route is mostly on Highway 9, passing through the charming town of Springdale before reaching the park entrance.
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-1-12"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-1-12"
                            aria-expanded="false"
                            aria-controls="collapse-1-12"
                          >
                            <h3 className="mb-0 d-flex align-items-center justify-content-between">
                              <span>
                                <span className="ti-map mr-3"></span>
                                Are there caves in Zion National Park?
                              </span>
                              <span className="faq-toggle-icon">
                                <i className="fa fa-plus"></i>
                                <i className="fa fa-minus"></i>
                              </span>
                            </h3>
                          </div>
                          <div id="collapse-1-12" className="collapse" aria-labelledby="heading-1-12" data-parent="#accordion-1">
                            <div className="card-body">
                              <p>
                                While Zion National Park is more famous for its canyons and towering rock formations, there are some cave-like formations. Weeping Rock and The Subway feature stunning alcoves and hidden waterfalls, creating cave-like environments. However, for true spelunking adventures, there are other locations in Utah better known for caves.
                              </p>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                )}

              {!(this.props.hideFaq && this.props.hideFaq === true) &&
                this.props.theme === 'four' && (
                  <div className="row pt-5">
                    <div className="col-md-6">
                      <div className="single-faq">
                        <h5>How can I pay for this?</h5>
                        <p>
                          Intrinsicly implement high standards in strategic
                          theme areas via inexpensive solutions. Assertively
                          conceptualize prospective bandwidth whereas
                          client-based imperatives.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="single-faq">
                        <h5>Is it possible to pay yearly?</h5>
                        <p>
                          Assertively iterate user friendly innovation without
                          open-source markets. Monotonectally extend resource
                          sucking manufactured products without high-payoff
                          paradigms. Objectively customize ubiquitous
                          information before economically sound relationships.{' '}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="single-faq">
                        <h5>Do you offer discounts on multiple items?</h5>
                        <p>
                          Dramatically target focused testing procedures after
                          holistic ideas. Collaboratively maximize high-payoff
                          ROI and value-added products. Distinctively deliver
                          cooperative collaboration and idea-sharing whereas
                          customized
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="single-faq">
                        <h5>Is VAT included in plan prices?</h5>
                        <p>
                          Distinctively simplify high-quality initiatives for
                          highly efficient applications. Monotonectally
                          repurpose integrated customer service after magnetic
                          e-services.{' '}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="single-faq">
                        <h5>Will I pay more for some features?</h5>
                        <p>
                          Enthusiastically pontificate resource-leveling supply
                          chains whereas scalable markets. Authoritatively
                          streamline resource maximizing methods of empowerment
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="single-faq">
                        <h5>
                          Why are there no limits on the number of messages?
                        </h5>
                        <p>
                          Assertively target turnkey ideas for market-driven
                          portals. Appropriately e-enable world-class
                          intellectual capital whereas 2.0 mindshare.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}