const { LaptopWindowsTwoTone } = require('@material-ui/icons');

module.exports = {
  hero: {
    title: 'ExplorOFF',
    description:
      'Leave your email below so we can share our specially curated outdoor must-go places near you on google maps!',
    bgImage: 'img/trip-itineraries-bg.jpg',
  },
  promo: {
    title: 'Full Road Trip Itineraries on Google Maps',
    description:
      'Stop wasting your time researching road trip ideas and quick weekend getaways. Plan your hikes, view points and outdoor activities and pin them to your google maps road trip only with ExplorOFF.',
    items: [
      {
        link: '/utah-national-parks-road-trip',
        title: 'Utah National Parks',
        description:
          'You don\'t need an entire week to explore Utah. Fit your Utah National Parks Road Trip in just a few days!',
        icon: 'ti-pin text-white',
      },
      {
        link:
          '/things-to-do-in-vegas-besides-party',
        title: 'Outdoor Activities in Las Vegas',
        description:
          'It\'s not your first time visiting Vegas and you are looking for things to do besides party, you are at the right spot.',
        icon: 'ti-pin text-white',
      },
      {
        link:
          '/kayak-colorado-river',
        title: '2-Day Colorado River Kayaking Trip',
        description:
          'Kayak 15 miles down the Colorado River, camp overnight along the way, stop at Antelope Canyon and Horseshoe Bend!',
        icon: 'ti-pin text-white',
      },
    ],
  },
  aboutUs: {
    title: {
      bold: 'Use your android or ios device',
      normal: '',
    },
    description:
      'Following reasons show advantages of adding AgencyCo to your lead pages, demos and checkouts',
    items: [
      {
        title: 'Clean Design',
        description: 'Increase sales by showing true dynamics of your website',
        icon: 'ti-vector text-white',
      },
      {
        title: 'Secure Data',
        description:
          'Build your online store’s trust using Social Proof & Urgency',
        icon: 'ti-lock text-white',
      },
      {
        title: 'Retina Ready',
        description:
          'Realize importance of social proof in customer’s purchase decision',
        icon: 'ti-eye text-white',
      },
    ],
  },
  price: 
  {
    title: 'Pricing Packages',
    description:
      "Uniquely maintain efficient expertise whereas cross functional deliverables. Continually leverage other's competitive mind share whereas enabled channels. ",
    utahPackages: [
        
      {
        price: 'Hike',
        license: 'Zion Narrows Bottom Up',
        isFeatured: false,
        features: [
          'Breath-taking a 9-mile hike through the blue water in the gorge. Water levels can vary from ankle-deep to chest high. Rent Shoes, Hiking Sticks, and Dry Gear on zionguru.com. Park at the visitor center and take the free Zion Park Shuttle to the Temple of Sinawava.',
        ],
        img:
          'img/utah-national-parks-road-trip/zion-narrows-bottom-up-.png',
        photoImg:
          'img/utah-national-parks-road-trip/zion-narrows-bottom-up-.png',
        fareharborURL:
          'https://www.google.com/maps/dir/Current+Location/37.28518,-112.94766',
      },
      {
        price: 'Hike',
        license: 'Angels Landing Trail',
        isFeatured: false,
        features: [
          'Access this 4.4-mile challenging hike with a 1,604 ft elevation gained by a free Zion Park Shuttle. Hike the first 2-miles on a well-maintained trail, and the rest half-mile follow a steep and narrow ridge with anchored support chains. Get rewarded with a perfect view.',
        ],
        img:
          'img/utah-national-parks-road-trip/angels-landing-trail.png',
        photoImg:
          'img/utah-national-parks-road-trip/angels-landing-trail.png',
        fareharborURL:
          'https://www.google.com/maps/dir/Current+Location/37.25909,-112.95122',
      },
      {
        price: 'Hike',
        license: 'Zion Canyon Overlook Trail',
        isFeatured: false,
        features: [
          'If you have a few extra hours in Zion, take this short 1-mile hike with a spectacular view at the end. Visit early in the morning to avoid crowds and enjoy the beautiful sunrise. Parking is extremely limited here, but there are overflow lots further down the road.',
        ],
        img:
          'img/utah-national-parks-road-trip/zion-canyon-overlook-trail.png',
        photoImg:
          'img/utah-national-parks-road-trip/zion-canyon-overlook-trail.png',
        fareharborURL:
          'https://www.google.com/maps/dir/Current+Location/37.21336,-112.94068',
      },
      {
        price: 'Hike',
        license: 'Navajo Loop and Queen\'s Garden Trail',
        isFeatured: false,
        features: [
          'This 3-mile heavily trafficked loop trail is subject to seasonal closure due to flooding. Check nps.gov for current weather conditions. Walk among the iconic white, red, and orange hoodoos. These hoodoos ("to bewitch") were created during the frost-wedging process.',
        ],
        img:
          'img/utah-national-parks-road-trip/navajo-loop-queen-garden-trail.png',
        photoImg:
          'img/utah-national-parks-road-trip/navajo-loop-queen-garden-trail.png',
        fareharborURL:
          'https://www.google.com/maps/dir/Current+Location/37.62337,-112.16724',
      },
      {
        price: 'Hike',
        license: 'Fairyland Loop Trail',
        isFeatured: false,
        features: [
          'Fairyland Loop Trail is a challenging 7.8-mile loop trail with incredible views of Bryce\'s famous hoodoos. The view is spectacular during the blue hour. It will take you around 4 hours but definitely plan some time for taking pictures and stopping to enjoy the view.',
        ],
        img:
          'img/utah-national-parks-road-trip/fairyland-loop-trail.png',
        photoImg:
          'img/utah-national-parks-road-trip/fairyland-loop-trail.png',
        fareharborURL:
          'https://www.google.com/maps/dir/Current+Location/37.64937,-112.14764',
      },
      {
        price: '$25',
        license: 'Mystic Hot Springs',
        isFeatured: true,
        features: [
          'Get this 2-hr Soaking Pass to enjoy the mineral-rich hot spring waters. The soaking area features two concrete pools and six vintage cast iron bathtubs. Soak in the water while soaking up breathtaking sunsets from one of the most IG-worthy spots in Utah.',
        ],
        img:
          'img/utah-national-parks-road-trip/mystic-hot-springs.png',
        photoImg:
          'img/utah-national-parks-road-trip/mystic-hot-springs.png',
        fareharborURL:
          'https://fareharbor.com/embeds/book/mystichotsprings/items/40603/?&sheet=235321&asn=fhdn&asn-ref=exploroff&full-items=yes&flow=no&branding=yes&bookable-only=yes',
      },
      {
        price: 'Hike',
        license: 'Hickman Bridge Trail',
        isFeatured: false,
        features: [
          'Hickman Bridge is a 1.7-mile, very relaxing, scenic trail in Capitol Reef National Park. A trail brochure available at the trailhead features 17 things to observe along the hike. Don\'t miss pit houses and Fremont River Valley, where the Fremont lived for generations.',
        ],
        img:
          'img/utah-national-parks-road-trip/hickman-bridge-trail.png',
        photoImg:
          'img/utah-national-parks-road-trip/hickman-bridge-trail.png',
        fareharborURL:
          'https://www.google.com/maps/dir/Current+Location/38.28871,-111.22774',
      },
      {
        price: 'Hike',
        license: 'Cassidy Arch Trail',
        isFeatured: false,
        features: [
          'Cassidy Arch is a 3.1-mile out-and-back trail that features hiking and rock climbing. This trail first begins with a steep incline which is the most challenging part. After that, the trail turns pretty moderate, although there are some narrow areas and steep drops.',
        ],
        img:
          'img/utah-national-parks-road-trip/cassidy-arch-trail.png',
        photoImg:
          'img/utah-national-parks-road-trip/cassidy-arch-trail.png',
        fareharborURL:
          'https://www.google.com/maps/dir/Current+Location/38.26374,-111.21578',
      },
      {
        price: 'View Point',
        license: 'Spotted Wolf Canyon',
        isFeatured: false,
        features: [
          'Quick scenic stop on your way to Arches Nation Park. Enjoy the view of the stunning and colorful San Rafael Reef\'s rock formations. Be careful as a short hike to the overlook gets pretty steep. Make sure to bring your camera and get some pictures during blue hour.',
        ],
        img:
          'img/utah-national-parks-road-trip/spotted-wolf-canyon.png',
        photoImg:
          'img/utah-national-parks-road-trip/spotted-wolf-canyon.png',
        fareharborURL:
          'https://www.google.com/maps/dir/Current+Location/38.92729,-110.40724',
      },
      {
        price: 'Hike',
        license: 'Delicate Arch Trail',
        isFeatured: false,
        features: [
          'The iconic 3.2-mile Delicate Arch Trail leads along with the beautiful red rock slabs to the largest free-standing arch in the park. Go for sunset and get there early for a good spot. There is no shade along this trail, so be sure to bring some water and head covering.',
        ],
        img:
          'img/utah-national-parks-road-trip/delicate-arch-trail.png',
        photoImg:
          'img/utah-national-parks-road-trip/delicate-arch-trail.png',
        fareharborURL:
          'https://www.google.com/maps/dir/Current+Location/38.73566,-109.52051',
      },
      {
        price: 'Hike',
        license: 'Devil\'s Garden',
        isFeatured: false,
        features: [
          'This 8-mile heavily trafficked loop trail offers incredible views of unique arches and a chance to see wildlife. On the route, you will see Tunnel Arch, Pine Tree Arch, Private Arch, Dark Angel Arch, Double O Arch, Partition Arch, Navajo Arch, and Landscape Arch.',
        ],
        img:
          'img/utah-national-parks-road-trip/dvils-garden.png',
        photoImg:
          'img/utah-national-parks-road-trip/dvils-garden.png',
        fareharborURL:
          'https://www.google.com/maps/dir/Current+Location/38.78237,-109.59509',
      },
      // {
      //   price: 'Hike',
      //   license: 'Double Arch Trail',
      //   isFeatured: false,
      //   features: [
      //     'If you have a few extra hours in Arches, take this short 0.6-mile highly trafficked hike with a spectacular view at the Double Arch. You can scramble to the top or enjoy the view from the bottom. Visit early in the morning to avoid crowds and get a parking spot.',
      //   ],
      //   img:
      //     'img/utah-national-parks-road-trip/double-arch-trail.png',
      //   photoImg:
      //     'img/utah-national-parks-road-trip/double-arch-trail.png',
      //   fareharborURL:
      //     'https://www.google.com/maps/dir/Current+Location/38.68828,-109.53838',
      // },
      {
        price: '$107',
        license: 'Fisher Towers',
        isFeatured: true,
        features: [
          'Fisher Towers Trip is the must-do 14-miles-long rafting route to the majestic Fisher Towers and the La Sal Mountains. Raft through Onion Creek, Professor Creek, New Rapid, and Rocky Rapid before hitting Whites Rapid: the best rapid on the river! P.S. Bring Snacks.',
        ],
        img:
          'img/utah-national-parks-road-trip/fisher-towers.png',
        photoImg:
          'img/utah-national-parks-road-trip/fisher-towers.png',
        fareharborURL:
          'https://fareharbor.com/embeds/book/redriveradventures/items/178515/?&sheet=235321&asn=fhdn&asn-ref=exploroff&full-items=yes&flow=no&branding=yes&bookable-only=yes',
      },
      // {
      //   price: '$125',
      //   license: 'Morning Glory aka Ephedra’s Grotto',
      //   isFeatured: true,
      //   features: [
      //     'This 3-mile adventure starts with a hike across petrified sand dunes. The first rappel (90 ft) is a stunning drop into a water-carved sandstone chamber. You will exit with a short walk to our second free-hanging rappel (120 ft) and a perfect Morning Glory Arch view.',
      //   ],
      //   img:
      //     'img/utah-national-parks-road-trip/morning-glory-aka-ephedras-grotto.png',
      //   photoImg:
      //     'img/utah-national-parks-road-trip/morning-glory-aka-ephedras-grotto.png',
      //   fareharborURL:
      //     'https://fareharbor.com/embeds/book/redriveradventures/items/179413/?&sheet=235321&asn=fhdn&asn-ref=exploroff&full-items=yes&flow=no&branding=yes&bookable-only=yes',
      // },
      // {
      //   price: '$75',
      //   license: 'Flatwater Fun',
      //   isFeatured: true,
      //   features: [
      //     'This 5-mile-long paddleboard trip borders Arches National Park. Float, paddle, and swim downstream of the Colorado River! This is a family-friendly flatwater trip, so you do not need to worry about rapids. Learn from our guides about Moab, Utah, and the local area.',
      //   ],
      //   img:
      //     'img/utah-national-parks-road-trip/flatwater-fun.png',
      //   photoImg:
      //     'img/utah-national-parks-road-trip/flatwater-fun.png',
      //   fareharborURL:
      //     'https://fareharbor.com/embeds/book/paddlemoab/items/288245/?&sheet=322262&asn=fhdn&asn-ref=exploroff&full-items=yes&flow=no&branding=yes&bookable-only=yes',
      // },
      {
        price: '$119',
        license: 'T-Rex 4x4 Tours',
        isFeatured: true,
        features: [
          'Cruise off the beaten path by Kawasaki Teryx 4 to explore the Hell\'s Revenge Trail on a 2.5hr off-road Moab adventure. Follow your experienced guide along the route to Devil’s Backbone, Lion’s Back, Echo Canyon, Abyss Canyon, Colorado River, and La Sal Mountains.',
        ],
        img:
          'img/utah-national-parks-road-trip/t-rex-utv-tours.png',
        photoImg:
          'img/utah-national-parks-road-trip/t-rex-utv-tours.png',
        fareharborURL:
          'https://fareharbor.com/embeds/book/moabtourismcenter/items/119802/?&sheet=259014&asn=fhdn&asn-ref=exploroff&full-items=yes&flow=no&branding=yes&bookable-only=yes',
      },
      {
        price: 'Hike',
        license: 'Mesa Arch Trail',
        isFeatured: false,
        features: [
          'If you have a few extra hours in Canyonlands, take this short 0.7-mile hike with a spectacular view of the Mesa Arch at the end. Visit early in the morning to capture sunrise and avoid crowds. Mesa Arch offers great views from the arch perched right on a cliff edge.',
        ],
        img:
          'img/utah-national-parks-road-trip/mesa-arch-trail.png',
        photoImg:
          'img/utah-national-parks-road-trip/mesa-arch-trail.png',
        fareharborURL:
          'https://www.google.com/maps/dir/Current+Location/38.38919,-109.86812',
      },
      // {
      //   price: 'Hike',
      //   license: 'Grand View Point Trail',
      //   isFeatured: false,
      //   features: [
      //     'Grand View Point is a 1.8-mile easy, out-and-back trail. The tail width is at least 4ft wide on the paved section, and the estimated grade is mostly gentle. It will take around 2hrs but plan extra time for the exceptional views of Colorado and Green River canyons.',
      //   ],
      //   img:
      //     'img/utah-national-parks-road-trip/grand-view-point-trail.png',
      //   photoImg:
      //     'img/utah-national-parks-road-trip/grand-view-point-trail.png',
      //   fareharborURL:
      //     'https://www.google.com/maps/dir/Current+Location/38.31075,-109.85658',
      // },
      // {
      //   price: 'Hike/Camp',
      //   license: 'Reflection Canyon',
      //   isFeatured: false,
      //   features: [
      //     'Reflection Canyon is a 15.2-mile intense, challenging trail recommended for very experienced adventurers only. Come early to get a good camping spot and see the beautiful sunrise. Make sure your vehicle has a ground clearance to get there and download an offline map.',
      //   ],
      //   img:
      //     'img/utah-national-parks-road-trip/reflection-canyon.png',
      //   photoImg:
      //     'img/utah-national-parks-road-trip/reflection-canyon.png',
      //   fareharborURL:
      //     'https://www.google.com/maps/dir/Current+Location/37.18837,-110.91841',
      // },
      {
        price: '$10',
        license: 'Horseshoe bend',
        isFeatured: false,
        features: [
          'Take a short 1.5-mile hike to the horseshoe-shaped Colorado River meander near Page, Arizona. This steep 4,200 ft above sea level overlook is your perfect sunset spot. It\'s a major tourist destination with over 2 million visitors per year. P.S. A parking fee applies.',
        ],
        img:
          'img/utah-national-parks-road-trip/horshoe-bend.png',
        photoImg:
          'img/utah-national-parks-road-trip/horshoe-bend.png',
        fareharborURL:
          'https://www.google.com/maps/dir/Current+Location/36.879116,-111.51042',
      },
    ],
    nevadaPackages: [
        {
        price: '$115',
        license: 'Emerald Cave Kayak Tour',
        isFeatured: true,
        features: [
          'Take this guided kayaking tour to experience the serenity of Emerald Cave, a picturesque natural attraction on the Colorado River. You\'ll launch from Willow Beach Marina, AZ, and then paddle north on the crystal clear Colorado River to the beautiful Emerald Cave.',
        ],
        img:
          'img/things-to-do-in-vegas-besides-party/emerald-cave-kayak-tour.png',
        photoImg:
          'img/things-to-do-in-vegas-besides-party/emerald-cave-kayak-tour.png',
        fareharborURL:
          'https://www.viator.com/tours/Las-Vegas/Emerald-Cave-Kayaking-Tour/d684-26719P8?pid=P00057350&mcid=42383&medium=link',
      },
      {
        price: '$118',
        license: 'Kayak Tour in the Black Canyon',
        isFeatured: true,
        features: [
          'Get picked up from your Las Vegas hotel and kayak down the Colorado River and enjoy the sights of the Black Canyon, spot wildlife like bighorn sheep, coyotes, or bald eagles, visit beautiful beaches, and take a dip in the emerald-green-colored water of Emerald Cave.',
        ],
        img:
          'img/things-to-do-in-vegas-besides-party/kayak-tour-in-the-black-canyon-from-las-vegas.png',
        photoImg:
          'img/things-to-do-in-vegas-besides-party/kayak-tour-in-the-black-canyon-from-las-vegas.png',
        fareharborURL:
          'https://www.viator.com/tours/Las-Vegas/Half-or-Full-Day-Kayaking-Tour-on-the-Colorado-River-from-Las-Vegas/d684-39939P2?pid=P00057350&mcid=42383&medium=link',
      },
      {
        price: '$180',
        license: 'Mojave Desert ATV Tour',
        isFeatured: true,
        features: [
          'Gear up for your 1.5 hours-guided ATV tour through the Mojave Desert. This tour is full of outdoor adventure memories, perfect if you have limited time. Ride off into the desert sky on the exclusive private trail not open to other companies or the general public.',
        ],
        img:
          'img/things-to-do-in-vegas-besides-party/mojave-desert-ATV-tour-from-las-vegas.png',
        photoImg:
          'img/things-to-do-in-vegas-besides-party/mojave-desert-ATV-tour-from-las-vegas.png',
        fareharborURL:
          'https://viator.com/tours/Las-Vegas/Mojave-Desert-ATV-Tour/d684-18018P8?pid=P00057350&mcid=42383&medium=link',
      },
      {
        price: '$475',
        license: 'Grand Canyon Deluxe Helicopter Tour',
        isFeatured: true,
        features: [
          'The Grand Canyon helicopter tour includes dive below the high desert to touch down on a private bluff 3,500 feet below the rim and just 300 feet above the Colorado River. Fly over Lake Mead National Recreation Area, Hoover Dam, and the Las Vegas Strip (champagne toast and snacks on us).',
        ],
        img:
          'img/things-to-do-in-vegas-besides-party/grand-canyon-deluxe-helicopter-tour-from-las-vegas.png',
        photoImg:
          'img/things-to-do-in-vegas-besides-party/grand-canyon-deluxe-helicopter-tour-from-las-vegas.png',
        fareharborURL:
          'https://www.viator.com/tours/Las-Vegas/Grand-Canyon-Deluxe-Helicopter-Tour-from-Las-Vegas/d684-5847LASWIN?pid=P00057350&mcid=42383&medium=link',
      },
      {
        price: '$146',
        license: 'Scooter Tours of Red Rock Canyon',
        isFeatured: true,
        features: [
          'Explore the canyon, pass through desert landscapes, red- and buff-colored rock formations, and sandstone and limestone cliffs that reach elevations up to 7,000 feet on this half-day scooter tour of the Red Rock canyon National Conservation Area.',
        ],
        img:
          'img/things-to-do-in-vegas-besides-party/scooter-tours-of-red-rock-canyon.png',
        photoImg:
          'img/things-to-do-in-vegas-besides-party/scooter-tours-of-red-rock-canyon.png',
        fareharborURL:
          'https://www.viator.com/tours/Las-Vegas/Red-Rock-Scooter-Tours/d684-14272P1?pid=P00057350&mcid=42383&medium=link',
      },
      {
        price: '$299',
        license: 'Hot Air Balloon Ride',
        isFeatured: true,
        features: [
          'Get a bird\'s-eye view of the stunning desert and gorgeous mountain scenery in a colorful hot air balloon. Choose between a sunrise or sunset departure to get away from the hustle and bustle of the Vegas Strip and join us on a journey that will take your breath away.',
        ],
        img:
          'img/things-to-do-in-vegas-besides-party/las-vegas-hot-air-balloon-ride.png',
        photoImg:
          'img/things-to-do-in-vegas-besides-party/las-vegas-hot-air-balloon-ride.png',
        fareharborURL:
          'https://www.viator.com/tours/Las-Vegas/Las-Vegas-Hot-Air-Balloon-Ride/d684-5100LASBALLOON?pid=P00057350&mcid=42383&medium=link',
      },
      {
        price: '$222',
        license: 'Vegas Mini Baja Buggy Tour',
        isFeatured: true,
        features: [
          'Ride in a specially-equipped buggy through the Nevada desert. Pilot over the area\'s most challenging 15-mile-long terrain wearing full safety gear. Note: the price displayed is a per-person rate based on a two-seater buggy; please see tour options for details.',
        ],
        img:
          'img/things-to-do-in-vegas-besides-party/vegas-mini-baja-buggy-half-day-tour.png',
        photoImg:
          'img/things-to-do-in-vegas-besides-party/vegas-mini-baja-buggy-half-day-tour.png',
        fareharborURL:
          'https://www.viator.com/tours/Las-Vegas/Mini-Baja-Buggy-Half-Day-Tour-from-Las-Vegas/d684-3611MINI?pid=P00057350&mcid=42383&medium=link',
      },
      {
        price: 'Hike',
        license: 'Cathedral Rock Trail',
        isFeatured: false,
        features: [
          'Cathedral Rock Trail is a moderate 9-mile-long moderate trail accessible by Suv. Go off to the right side of the trail for a great view of the Stuart Range, Squaw Lake, Cathedral Rock, and Mount Adam. Come prepared to scramble uphill for the last half a mile.',
        ],
        img:
          'img/things-to-do-in-vegas-besides-party/cathedral-rock-trail.png',
        photoImg:
          'img/things-to-do-in-vegas-besides-party/cathedral-rock-trail.png',
        fareharborURL:
          'https://www.google.com/maps/dir/Current+Location/36.25617,-115.64547',
      },
      {
        price: 'Hike',
        license: 'Calico Tanks Trail',
        isFeatured: false,
        features: [
          'Enjoy beautiful sandstone formations in the hills and a nice view of Vegas on this 2-mile-long moderate trail located at Red Rock Canyon. Lots of rock scrambling and climbing over boulders. Book a timed reservation up to 30 days in advance on www.recreation.gov.',
        ],
        img:
          'img/things-to-do-in-vegas-besides-party/calico-tanks-trail.png',
        photoImg:
          'img/things-to-do-in-vegas-besides-party/calico-tanks-trail.png',
        fareharborURL:
          'https://www.google.com/maps/dir/Current+Location/36.16233,-115.45044',
      },
      {
        price: 'Hike',
        license: 'Ice Box Canyon Trail',
        isFeatured: false,
        features: [
          'Challenging 2.3-mile-long rock scrambling hike with lots of bouldering and climbing included. Watch your footing as you go through some wobbly areas to enjoy a beautiful waterfall at the end. Book a timed reservation up to 30 days in advance on www.recreation.gov.',
        ],
        img:
          'img/things-to-do-in-vegas-besides-party/ice-box-canyon-trail.png',
        photoImg:
          'img/things-to-do-in-vegas-besides-party/ice-box-canyon-trail.png',
        fareharborURL:
          'https://www.google.com/maps/dir/Current+Location/36.1501,-115.4838',
      },
      {
        price: 'Hike',
        license: 'First Creek Canyon Trail',
        isFeatured: false,
        features: [
          'First Creek Canyon Trail is a 3.4-mile moderate trail in Red Rock Canyon. To get to the hidden waterfalls, when you get to the very first tree, turn 90° to your right and walk about 100ft. Book a timed reservation up to 30 days in advance on www.recreation.gov.',
        ],
        img:
          'img/things-to-do-in-vegas-besides-party/first-creek-canyon-trail.png',
        photoImg:
          'img/things-to-do-in-vegas-besides-party/first-creek-canyon-trail.png',
        fareharborURL:
          'https://www.google.com/maps/dir/Current+Location/36.0813,-115.44813',
      },
      {
        price: 'Hike',
        license: 'Pine Creek Canyon Trail',
        isFeatured: false,
        features: [
          'Watch advanced climbers concurring Sheer Canyon, or join them if you are confident in your safety! Otherwise, enjoy pine trees and some bouldering along a creek on this easy 3-mile-long hike. Book a timed reservation up to 30 days in advance on www.recreation.gov.',
        ],
        img:
          'img/things-to-do-in-vegas-besides-party/pine-creek-canyon-trail.png',
        photoImg:
          'img/things-to-do-in-vegas-besides-party/pine-creek-canyon-trail.png',
        fareharborURL:
          'https://www.google.com/maps/dir/Current+Location/36.12872,-115.47361',
      },
      {
        price: 'Hike',
        license: 'Lone Mountain Loop Trail',
        isFeatured: false,
        features: [
          'Take your flashlight and head for sunset to see the Las Vegas strip light up. This easy 3.2-mile long Lone Mountain Loop Trail is suitable for all skill levels but gets pretty steep at the end. Get lucky and see a ringtail cat reported by 3 hikers on AllTrails.',
        ],
        img:
          'img/things-to-do-in-vegas-besides-party/lone-mountain-loop-trail.png',
        photoImg:
          'img/things-to-do-in-vegas-besides-party/lone-mountain-loop-trail.png',
        fareharborURL:
          'https://www.google.com/maps/dir/Current+Location/36.23997,-115.31065',
      },
      {
        price: 'Scenic Drive',
        license: 'White Domes Road',
        isFeatured: false,
        features: [
          'Drive through the Valley of Fire and enjoy the beauty of White Domes, Petroglyph Canyon, Mouse\'s Tank, and Rainbow Vista. Take tons of pictures and spot wildlife on the way but be careful with cars approaching. Leave before 4:30 PM; otherwise, you may get ticketed.',
        ],
        img:
          'img/things-to-do-in-vegas-besides-party/white-domes-road.png',
        photoImg:
          'img/things-to-do-in-vegas-besides-party/white-domes-road.png',
        fareharborURL:
          'https://www.google.com/maps/dir/Current+Location/36.42816,-114.5135',
      },
      {
        price: 'Hike',
        license: 'Fire Wave Trail',
        isFeatured: false,
        features: [
          'A 1.5-mile easy trail features small canyons and a cave. Get a better view by starting at the parking lot\'s right-hand side of the loop. Come early in the morning to avoid crowds. Endless photo opportunities, leave before 4:30 PM; otherwise, you may get ticketed.',
        ],
        img:
          'img/things-to-do-in-vegas-besides-party/fire-wave-trail.png',
        photoImg:
          'img/things-to-do-in-vegas-besides-party/fire-wave-trail.png',
        fareharborURL:
          'https://www.google.com/maps/dir/Current+Location/36.48819,-114.52894',
      },
      {
        price: 'Hike',
        license: 'Gold Strike Canyon Hot Springs',
        isFeatured: false,
        features: [
          'A 6.0-mile out-and-back trail near Boulder City, NV. Diving your head in hot springs is not recommended! There is the brain-eating amoeba called naegleria fowleri, that enters the body through the nose, so keep your head above the water.',
        ],
        img:
          'img/things-to-do-in-vegas-besides-party/gold-strike-canyon-hot-springs.png',
        photoImg:
          'img/things-to-do-in-vegas-besides-party/gold-strike-canyon-hot-springs.png',
        fareharborURL:
          'https://www.google.com/maps/dir/Current+Location/36.01016,-114.77474',
      },
      {
        price: 'Hike',
        license: 'Mary Jane Falls Trail',
        isFeatured: false,
        features: [
          'A 4-mile moderate out-and-back trail near Mount Charleston. A lot of chipmunks and pretty wildflowers. Trail can get icy and snowy over the winter, spikes and hiking poles are highly recommended. Also, some parking lots might be closed over the winter. Dogs on a leash are allowed. ',
        ],
        img:
          'img/things-to-do-in-vegas-besides-party/mary-jane-falls-trail.png',
        photoImg:
          'img/things-to-do-in-vegas-besides-party/mary-jane-falls-trail.png',
        fareharborURL:
          'https://www.google.com/maps/dir/Current+Location/36.26592,-115.65849',
      },
    ],
    coloradoRiverPackages: [
      {
        price: '$100',
        license: 'Two-Day Kayak Rental',
        isFeatured: true,
        features: [
          'Make a 2:30 PM backhaul reservation with Marble Canyon Outfitters at 800-533-7339. Rent kayaks here and load them up on your vehicle. Meet the Marble Canyon Outfitters at Lee\'s Ferry boat ramp, and get a 15-mile ride upstream. Kayak down the Colorado River and camp on the way.',
        ],
        img:
          'img/kayak-colorado-river/two-day-kayak-rental.png',
        photoImg:
          'img/kayak-colorado-river/two-day-kayak-rental.png',
        fareharborURL:
          'https://fareharbor.com/embeds/book/kayakpowell/items/25080/?&sheet=235321&asn=fhdn&asn-ref=exploroff&full-items=yes&flow=no&branding=yes&bookable-only=yes',
      },
     {
        price: '$160',
        license: 'Antelope Canyon Tour',
        isFeatured: true,
        features: [
          'Formed by the erosion of Navajo Sandstone, Upper Antelope Canyon is referred to by the Navajo as "the place where water runs through rocks". Upper Antelope Canyon is the most popular slot canyon with beams of direct sunlight radiating down from openings from the top.',
        ],
        img:
          'img/kayak-colorado-river/antylope-canyon-tour.png',
        photoImg:
          'img/kayak-colorado-river/antylope-canyon-tour.png',
        fareharborURL:
          'https://www.viator.com/tours/Page/Upper-Antelope-Canyon-Ticket/d23393-124350P1?pid=P00057350&mcid=42383&medium=link',
      },
    {
        price: '$10',
        license: 'Horseshoe bend',
        isFeatured: false,
        features: [
          'Take a 1.5-mile hike to the horseshoe-shaped Colorado River meander near Page, AZ. This steep 4,200 ft above sea level overlook is your perfect sunset spot. It\'s a major tourist destination with over 2 million visitors per year.',
          'P.S. A $10 parking fee applies.',
        ],
        img:
          'img/utah-national-parks-road-trip/horshoe-bend.png',
        photoImg:
          'img/utah-national-parks-road-trip/horshoe-bend.png',
        fareharborURL:
          'https://www.google.com/maps/dir/Current+Location/36.879116,-111.51042',
      },
    ],
    glacierMemorialPackages: [
      {
        price: 'Hike',
        license: 'Grinnell Glacier Trail',
        isFeatured: false,
        features: [
          'Grinnell Glacier Trail is a hard 11.2-mile out-and-back trail that takes around six hours to finish. It gets pretty windy at the top, so we recommend bringing extra layers. Be ready to get wet and hike on the slippery ground when going under cascading waterfalls.',
        ],
        img:
          'img/best-things-to-do-in-glacier-national-park-in-may/grinnell-glacier-trail.png',
        photoImg:
          'img/best-things-to-do-in-glacier-national-park-in-may/grinnell-glacier-trail.png',
        fareharborURL:
          'https://www.google.com/maps/dir/Current+Location/48.79729,-113.66872/',
      }, 
      {
        price: '$60',
        license: 'Bike Going To The Sun Road',
        isFeatured: true,
        features: [
          'Make your bike reservation at Glacier Outfitters (located inside the park) to assure your entrance. Your reservation provides a free entry ticket, which is not expensive but hard to reserve. Highly recommend renting an e-bike unless you are in superior shape.',
        ],
        img:
          'img/best-things-to-do-in-glacier-national-park-in-may/gttsr-bike-rental.png',
        photoImg:
          'img/best-things-to-do-in-glacier-national-park-in-may/gttsr-bike-rental.png',
        fareharborURL:
          'https://www.goglacieroutfitters.com/going-to-the-sun-road-bike-rentals/',
      },
      {
        price: 'Hike',
        license: 'Hidden Lake Overlook',
        isFeatured: false,
        features: [
          'Hidden Lake Overlook is a moderately challenging 2.9-mile out-and-back trail with a pretty steep elevation gain. The majority of the trail is a broadwalk, and it\'s clearly marked. The view of the lake is breathtaking, and the view point can get crowdded at times.',
        ],
        img:
          'img/best-things-to-do-in-glacier-national-park-in-may/hidden-lake-overlook.png',
        photoImg:
          'img/best-things-to-do-in-glacier-national-park-in-may/hidden-lake-overlook.png',
        fareharborURL:
          'https://www.google.com/maps/dir/Current+Location/48.69576,-113.71707/',
      },
     {
        price: '$85',
        license: 'Guided Rafting Experience',
        isFeatured: true,
        features: [
          'Get a “window seat” and become part of the paddling crew! This scenic whitewater rafting experience will highlight your trip to Glacier National Park. Make sure the weather is nice and shiny; you will get wet, especially if you will decide to seat at the front!',
        ],
        img:
          'img/best-things-to-do-in-glacier-national-park-in-may/guided-rafting-experience.png',
        photoImg:
          'img/best-things-to-do-in-glacier-national-park-in-may/guided-rafting-experience.png',
        fareharborURL:
          'https://www.viator.com/tours/West-Glacier/Half-Day-Whitewater/d50559-123783P1?pid=P00057350&mcid=42383&medium=link',
      },
    {
        price: 'View Point',
        license: 'Lake McDonald',
        isFeatured: false,
        features: [
          'Drive up to Lake McDonald dock in Apgar and take some gorgeous photos of the lake, famously colored rocks, and the beauty of Montana. Hop into the lake if you are brave enough. Although it might be pretty warm outside, the water temperature never gets above 50°.',
        ],
        img:
          'img/best-things-to-do-in-glacier-national-park-in-may/lake-mcdonald.png',
        photoImg:
          'img/best-things-to-do-in-glacier-national-park-in-may/lake-mcdonald.png',
        fareharborURL:
          'https://www.google.com/maps/dir/Current+Location/48.528649,%20-113.991566',
      },
      {
        price: 'Hike',
        license: 'Avalanche Lake Trail',
        isFeatured: false,
        features: [
          'Not too challenging 5.9-mile hike around Lake Mc Donald. If you go past the sign at the end, there is a hidden waterfall. There are multiple picnic tables along the route so you can complement your meal with a gorgeous view! Best times to visit June - October.',
        ],
        img:
          'img/best-things-to-do-in-glacier-national-park-in-may/avalanche-lake-trail.png',
        photoImg:
          'img/best-things-to-do-in-glacier-national-park-in-may/avalanche-lake-trail.png',
        fareharborURL:
          'https://www.google.com/maps/dir/Current+Location/48.67945,-113.81956',
      },
    ],
    outdoorActivitiesPackages: [
      {
        price: '$125',
        license: 'Ride in "The Beast?"',
        isFeatured: false,
        features: [
          'Guaranteed to be the highlight of your trip to Moab. Experience steep hair-raising climbs and descents on roller coaster-type waves of slickrock, view dinosaur tracks, hear an echo at the Abyss and see an incredible overlook of the Colorado River from high above.',
        ],
        img:
          'img/jeep-tours-moab-ut.jpg',
        photoImg:
          'img/jeep-tours-moab-ut.jpg',
        fareharborURL:
          'https://fareharbor.com/embeds/book/moabgrandtours/items/124984/?sheet=257251&asn=fhdn&asn-ref=exploroff&full-items=yes&flow=no',
      },
      {
        price: '$59',
        license: 'Catch a Cali Wave',
        isFeatured: true,
        features: [
          'Make learning to surf both fun and safe for everyone. Surfari Surf School is conveniently located next to the beach in Mission Beach, California. Instructors are local surfers with a passion for teaching surfing. Stop by the surf school to discover your Surfari adventure today!',
        ],
        img:
          'img/california-surf.jpg',
        photoImg:
          'img/california-surf.jpg',
        fareharborURL:
          'https://fareharbor.com/embeds/book/surfarisurfschool/items/19451/?sheet=229151&asn=fhdn&asn-ref=exploroff&full-items=yes&flow=no',
      },
      {
        price: '$99',
        license: 'Cruise Off the Beaten Path',
        isFeatured: false,
        features: [
          "Explore the Hell's Revenge Trail on a 2.5-hour off-road adventure from Moab. Navigate a Kawasaki Teryx 4 — a 4x4 vehicle equipped for traversing sandstone domes and slickrock ledges. Experience the thrill of steep climbs and descents with views of La Sal Mountains, Abyss Canyon, and Colorado River.",
        ],
        img:
          'img/t-rex-4x4-tours-moab.jpg',
        photoImg:
          'img/t-rex-4x4-tours-moab.jpg',
        fareharborURL:
          'https://fareharbor.com/embeds/book/moabtourismcenter/items/119802/?sheet=259014&asn=fhdn&asn-ref=exploroff&full-items=yes&flow=no',
      },
      {
        price: '$255',
        license: 'Venere Private Charter',
        isFeatured: false,
        features: [
          'Charter a private yacht for corporate events, birthday parties, proposals, or any special event!',
        ],
        img:
          'img/san-diego-charter.jpg',
        photoImg:
          'img/san-diego-charter.jpg',
        fareharborURL:
          'https://fareharbor.com/embeds/book/gonesailingcharters/items/40025/?sheet=220993&asn=fhdn&asn-ref=exploroff&full-items=yes&flow=no',
      },
      {
        price: '$48',
        license: 'Dolphin & Whale Watch Day Sail',
        isFeatured: false,
        features: [
          'Spot dolphins, whales and other sea creatures! Sail on the largest touring sailing yacht in Mission Bay, SPIRIT',
        ],
        img:
          'img/whale-watch-sail.jpg',
        photoImg:
          'img/whale-watch-sail.jpg',
        fareharborURL:
          'https://fareharbor.com/embeds/book/gonesailingcharters/items/102805/?sheet=220993&asn=fhdn&asn-ref=exploroff&full-items=yes&flow=no',
      },
      {
        price: '$10',
        license: 'Take Off on a Wave',
        isFeatured: false,
        features: [
          'Ocean is alsways a great idea! Take off on a wave with wetsuit, stand-up paddleboard or surfboard rentals.',
        ],
        img:
          'img/rent-a-surfboard.jpg',
        photoImg:
          'img/rent-a-surfboard.jpg',
        fareharborURL:
          'https://fareharbor.com/embeds/book/surfarisurfschool/items/20461/?sheet=229151&asn=fhdn&asn-ref=exploroff&full-items=yes&flow=no',
      },
      {
        price: '$250',
        license: 'Moab Jeep Tour',
        isFeatured: false,
        features: [
          "Experience Moab's most famous trail, Hell's Revenge, to the max with the experienced guide!",
        ],
        img:
          'img/moab-jeep-tour.jpg',
        photoImg:
          'img/moab-jeep-tour.jpg',
        fareharborURL:
          'https://fareharbor.com/embeds/book/moabgrandtours/items/170249/?sheet=257251&asn=fhdn&asn-ref=exploroff&full-items=yes&flow=no',
      },
      {
        price: '$109',
        license: 'Whitewater Adventure',
        isFeatured: false,
        features: [
          'Enjoy one of the raddest places in the continental USA. Hurry up space is limited!',
        ],
        img:
          'img/whitewater-rafting.jpg',
        photoImg:
          'img/whitewater-rafting.jpg',
        fareharborURL:
          'https://fareharbor.com/embeds/book/adrift/items/46826/calendar/2020/05/?flow=no&asn=fhdn&asn-ref=exploroff&sheet=229236&full-items=yes&flow=no',
      },
      {
        price: '$934',
        license: '4 Day Rafting Trip',
        isFeatured: false,
        features: [
          'Start rafting at the Gates of Lodore in Colorado and end at Split Mountain Campground in Utah.',
        ],
        img:
          'img/white-water-rafting-near-me.jpg',
        photoImg:
          'img/white-water-rafting-near-me.jpg',
        fareharborURL:
          'https://fareharbor.com/embeds/book/adrift/items/46830/?sheet=229236&asn=fhdn&asn-ref=exploroff&full-items=yes&flow=no',
      },
      {
        price: '$189',
        license: 'Ride as the Sun Sets',
        isFeatured: false,
        features: [
          'Diggin’ in the dirt while enjoying the color of the desert and Red Rock come alive under the sunset.',
        ],
        img:
          'img/utv-utah.jpg',
        photoImg:
          'img/utv-utah.jpg',
        fareharborURL:
          'https://fareharbor.com/embeds/book/atvadventures/items/43478/?sheet=254556&asn=fhdn&asn-ref=exploroff&full-items=yes&flow=no',
      },
      {
        price: '$149',
        license: 'Sandstone Teaser',
        isFeatured: false,
        features: [
          'Climb up the gorgeous Red Plateau, pass through spectacular views of lakes and mountain ranges, and see famous Garfield Rock!',
        ],
        img:
          'img/atv-utah.jpg',
        photoImg:
          'img/atv-utah.jpg',
        fareharborURL:
          'https://fareharbor.com/embeds/book/atvadventures/items/12416/?sheet=254556&asn=fhdn&asn-ref=exploroff&full-items=yes&flow=no',
      },
      {
        price: '$105',
        license: 'Guided Rock Climbing',
        isFeatured: false,
        features: [
          'Perfect for those that are new to the sport, gym climbers who want to climb outside or for folks who have been away from the rock for a while.',
        ],
        img:
          'img/guided-rock-climbing.jpg',
        photoImg:
          'img/guided-rock-climbing.jpg',
        fareharborURL:
          'https://fareharbor.com/embeds/book/redriveradventures/items/179789/?sheet=235321&asn=fhdn&asn-ref=exploroff&full-items=yes&flow=no',
      },
      {
        price: '$25',
        license: 'Full Day Bike Rental',
        isFeatured: false,
        features: [
          'Expore the bay by bike! Deluxe Comfort Hybrid bikes are perfect for riding almost anywhere!',
        ],
        img:
          'img/bike-rental-san-francisco.jpg',
        photoImg:
          'img/bike-rental-san-francisco.jpg',
        fareharborURL:
          'https://fareharbor.com/embeds/book/blazingsaddles/items/78799/?ref=bike&sheet=252293&asn=fhdn&asn-ref=exploroff&full-items=yes&flow=no',
      },
      {
        price: '$71',
        license: 'Napa History Kayak Tour',
        isFeatured: false,
        features: [
          'Learn about Napa Valley history, wine, and wildlife! Perfect for all fitness levels and experience, so stop thinking and go for it!',
        ],
        img:
          'img/kayak-napa.jpg',
        photoImg:
          'img/kayak-napa.jpg',
        fareharborURL:
          'https://fareharbor.com/embeds/book/enjoy-napa-valley/items/73713/?ref=kayak=221003&asn=fhdn&asn-ref=exploroff&full-items=yes&flow=no',
      },
      {
        price: '$65',
        license: 'SD Watersports Daily Rentals',
        isFeatured: false,
        features: [
          "Paddle through the America's Cup Harbor, take a tour of the on-water restaurants or venture out of the cove for beautiful views.",
        ],
        img:
          'img/paddle-boarding-san-diego.jpg',
        photoImg:
          'img/paddle-boarding-san-diego.jpg',
        fareharborURL:
          'https://fareharbor.com/embeds/book/oexpointloma/items/97885/?ref=sup&sheet=219526&asn=fhdn&asn-ref=exploroff&full-items=yes&flow=no',
      },
      {
        price: '$60',
        license: 'Wild & Scenic Verde River',
        isFeatured: false,
        features: [
          'This self-guided kayak adventure on Verde River is a blast! Puddle 5 miles down the river and enjoy the view.',
        ],
        img:
          'img/self-guided-kayaking-near-me.jpg',
        photoImg:
          'img/self-guided-kayaking-near-me.jpg',
        fareharborURL:
          'https://fareharbor.com/embeds/book/verdeadventure/items/69779/?sheet=226038&asn=fhdn&asn-ref=exploroff&full-items=yes&flow=no',
      },
      {
        price: '$35',
        license: 'Tubing Madness',
        isFeatured: false,
        features: [
          'Fun in the sun for the whole family! You will also get water cannons for epic water fights.',
        ],
        img:
          'img/tubing-arizona.jpg',
        photoImg:
          'img/tubing-arizona.jpg',
        fareharborURL:
          'https://fareharbor.com/embeds/book/verdeadventure/items/69793/?sheet=226038&asn=fhdn&asn-ref=exploroff&full-items=yes&flow=no',
      },
      {
        price: '$230',
        license: 'UTV Rental for Four',
        isFeatured: false,
        features: [
          'Go explore on your own! Get a map and hit the road. Rentals are available for 2 - 6 hours.',
        ],
        img:
          'img/utv-arizona.jpg',
        photoImg:
          'img/utv-arizona.jpg',
        fareharborURL:
          'https://fareharbor.com/embeds/book/vortexatvrentals/items/137281/?ref=atv&sheet=221237&asn=fhdn&asn-ref=exploroff&full-items=yes&flow=no',
      },
      {
        price: '$140',
        license: 'Mountain Bike Tour',
        isFeatured: false,
        features: [
          'Experience Red Rock Canyon on a mountain bike tour. This tour lets you take in the beauty and go places you may only see while cycling.',
        ],
        img:
          'img/mountain-biking-las-vegas.jpg',
        photoImg:
          'img/mountain-biking-las-vegas.jpg',
        fareharborURL:
          'https://fareharbor.com/embeds/book/cyclevegas/items/78634/?sheet=226033&asn=fhdn&asn-ref=exploroff&full-items=yes&flow=no',
      },
      {
        price: '$140',
        license: 'Road/Electric Bike Tour',
        isFeatured: false,
        features: [
          'Experience Red Rock Canyon famous drive and learn about the geology and ecology of the park.',
        ],
        img:
          'img/red-rock-cycle.jpg',
        photoImg:
          'img/red-rock-cycle.jpg',
        fareharborURL:
          'https://fareharbor.com/embeds/book/cyclevegas/items/78636/?sheet=226033&asn=fhdn&asn-ref=exploroff&full-items=yes&flow=no',
      },
      {
        price: '$449',
        license: 'Private 4x4 Tour',
        isFeatured: false,
        features: [
          'Enjoy the tour that is customized specifically for you. The private tour is an opportunity to make memories with your loved ones that will last for at lifetime.',
        ],
        img:
          'img/moab-utv-tour.jpg',
        photoImg:
          'img/moab-utv-tour.jpg',
        fareharborURL:
          'https://fareharbor.com/embeds/book/moabtourismcenter/items/119811/?ref=4x4&sheet=259014&asn=fhdn&asn-ref=exploroff&full-items=yes&flow=no',
      },
            {
        price: '$300',
        license: 'Hoover Dam Trike Tour',
        isFeatured: false,
        features: [
          'This hoover dam trike tour is full of iconic views! Get ready for a fun time as you take control of your own easy-to-ride Rewaco trike motorcycle!',
        ],
        img:
          'img/hoover-dam-trike-tour.jpg',
        photoImg:
          'img/hoover-dam-trike-tour.jpg',
        fareharborURL:
          'https://fareharbor.com/embeds/book/vegastrikeadventures/items/175101/calendar/2021/10/?flow=no&asn=fhdn&asn-ref=exploroff&sheet=236440&full-items=yes&flow=no',
      },
      {
        price: '$450',
        license: 'Guided Mojave ATV Tour',
        isFeatured: false,
        features: [
          'Take this Mojave ATV guided tour to experience terrain in the Mojave Desert that few people ever get to see. Ride 2021 Polaris RZR XP 1000!',
        ],
        img:
          'img/mojave-atv.jpg',
        photoImg:
          'img/mojave-atv.jpg',
        fareharborURL:
          'https://fareharbor.com/embeds/book/vegasoffroadtours/items/310119/calendar/2021/11/?asn=fhdn&asn-ref=exploroff&sheet=113703&full-items=yes&flow=no',
      },
      {
        price: '$399',
        license: 'Self-Guided ATV Mojave Desert',
        isFeatured: false,
        features: [
          'Take this ATV Mojave Desert self-guided tour to experience terrain in the Mojave Desert that few people ever get to see. Ride 2021 Polaris RZR XP 1000!',
        ],
        img:
          'img/atv-mojave-desert.jpg',
        photoImg:
          'img/atv-mojave-desert.jpg',
        fareharborURL:
          'https://fareharbor.com/embeds/book/vegasoffroadtours/items/289996/calendar/2021/11/?asn=fhdn&asn-ref=exploroff&sheet=113703&full-items=yes&flow=no',
      },
      {
        price: '$2,400',
        license: 'Romantic Hot Air Balloon Ride',
        isFeatured: false,
        features: [
          'This once-in-a-lifetime Romantic Hot Air Balloon ride will give you a never-before-seen view of Las Vegas and a chance to try something new!',
        ],
        img:
          'img/romantic-hot-air-balloon-rides.jpg',
        photoImg:
          'img/romantic-hot-air-balloon-rides.jpg',
        fareharborURL:
          'https://fareharbor.com/embeds/book/phototoursvegas/items/250059/calendar/2021/10/?asn=fhdn&asn-ref=exploroff&sheet=290891&full-items=yes&flow=no',
      },
      {
        price: '$489',
        license: 'Antelope Canyon Private Tour',
        isFeatured: false,
        features: [
          'Explore the beauty of AZ with this Antelope Canyon Private Tour. All areas of the Antelope Canyon are only accessible via guided tour.',
        ],
        img:
          'img/antelope-canyon-private-tour.jpg',
        photoImg:
          'img/antelope-canyon-private-tour.jpg',
        fareharborURL:
          'https://fareharbor.com/embeds/book/marvittours/items/272201/calendar/2021/10/?flow=no&asn=fhdn&asn-ref=exploroff&sheet=307939&full-items=yes&flow=no',
      },
    ],
  },
  testimonial: {
    title: 'What customer say about us',
    description:
      'Rapidiously morph transparent internal or "organic" sources whereas resource sucking e-business. Conveniently innovate compelling internal.',
    comments: [
      {
        name: 'Arabella Ora',
        company: 'AgencyCo',
        image: 'img/client-2.jpg',
        comment:
          'Completely build backend ROI whereas cross-media metrics. Collaboratively deploy customer directed web-readiness via global testing procedures. Appropriately reinvent distributed innovation without proactive catalysts for change. Distinctively.',
      },
      {
        name: 'John Charles',
        company: 'ThemeTags',
        image: 'img/client-1.jpg',
        comment:
          "If you can design one thing you can design everything with Front. Just believe it. Collaboratively repurpose performance based e-commerce without cost. It's beautiful and the coding is done quickly and seamlessly.",
      },
      {
        name: 'Jeremy Jere',
        company: 'WorldCheck',
        image: 'img/client-3.jpg',
        comment:
          "The template is really nice and offers quite a large set of options. It's beautiful and the coding is done. If you can design one thing you can design everything with Front. Just believe it.",
      },
      {
        name: 'Ciaran Kierce',
        company: 'AppCo',
        image: 'img/client-4.jpg',
        comment:
          'I just wanted to share a quick note and let you know that you guys do a really good job. I’m glad I decided to work with you. It’s really great how easy your websites are to update and manage. I never have any problem at all.',
      },
    ],
  },
  contact: {
    title: 'Contact with us',
    description:
      "It's very easy to get in touch with us. Just use the contact form or pay us a visit for a coffee at the office. Dynamically innovate competitive technology after an expanded array of leadership.",

    office: 'Head Office',
    address: '121 King St, Melbourne VIC 3000, Australia',
    phone: '+1 724 799 5786',
    email: 'hello@yourdomain.com',
  },
  trustedCompany: [
    'img/client-1-color.png',
    'img/client-6-color.png',
    'img/client-3-color.png',
    'img/client-4-color.png',
    'img/client-5-color.png',
  ],
  trustedCompanyGray: [
    'img/client-1-gray.png',
    'img/client-2-gray.png',
    'img/client-3-gray.png',
    'img/client-4-gray.png',
    'img/client-5-gray.png',
  ],
  trustedCompanyAboutUs: {
    title: 'Trusted by companies',
    description:
      'Rapidiously morph transparent internal or "organic" sources whereas resource sucking e-business. Conveniently innovate compelling internal.',
    companies: [
      'img/client-5-color.png',
      'img/client-1-color.png',
      'img/client-6-color.png',
      'img/client-3-color.png',
      'img/client-4-color.png',
      'img/client-5-color.png',
      'img/client-7-color.png',
      'img/client-2-color.png',
      'img/client-1-color.png',
      'img/client-3-color.png',
    ],
  },
  teamMember: {
    members: [
      {
        name: 'Yulia Vasilyeva',
        designation: 'Founder',
        image: 'img/team-4.jpg',
        facebook: 'https://www.facebook.com/yulia.vasilyeva.10',
        instagram: 'https://instagram.com/vasekkkkk',
        linkedin: 'https://www.linkedin.com/in/yulia-vasilyeva',
      },
      {
        name: 'Aigera Marat',
        designation: 'Contributor',
        image: 'img/team-2.jpg',
        facebook: 'https://www.facebook.com/Aigerusha',
        instagram: 'https://www.instagram.com/mcgerych',
        linkedin: 'https://www.linkedin.com/in/aikerim-tungyshbayeva-236a3a64/',
      },
      {
        name: 'Dmitry Bakcheev',
        designation: 'Contributor',
        image: 'img/team-3.jpg',
        facebook: 'https://www.facebook.com/profile.php?id=100000739833086',
        instagram: 'https://www.instagram.com/yasnoponyatno',
        linkedin: 'https://www.linkedin.com/in/dmitry-bakcheev-27a4a1199',
      },
    ],
  },
  video: {
    link: 'https://www.youtube.com/embed/4UWzLl6Lfjo',
  },
};
