import React from 'react';
import _data from '../../data';
import { SRLWrapper } from 'simple-react-lightbox';
import SimpleReactLightbox from 'simple-react-lightbox';

export default class Pricing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      price: {},
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

    this.setState({
      price: _data.price,
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="overflow-hidden">
          <section
            id="nevada-pricing"
            className={
              'package-section ptb-55 ' +
              (this.props.bgColor && this.props.bgColor === 'gray'
                ? 'gray-light-bg'
                : 'background-shape-right')
            }
          >
            <div className="container">
              <div className="row justify-content-center">
                {(this.state.price.nevadaPackages || []).map((_package, index) => {
                  return (
                    <div className="col-lg-4 col-md" key={index}>
                      <div
                        className={
                          'card text-center single-pricing-pack ' +
                          (_package.isFeatured ? 'popular-price' : '')
                        }
                      >
                        <div className="pt-4">
                          <h3>{_package.license}</h3>
                        </div>
                        <div className="pricing-img mt-4">
                          <img
                            src={_package.photoImg}
                            alt={_package.photoImg}
                            className="img-fluid"
                          />
                        </div>
                        <div className="card-header py-4 border-0 pricing-header">
                          <div className="h1 text-center mb-0">
                            <span className="price font-weight-bolder">
                              {_package.price}
                            </span>
                          </div>
                        </div>
                        <div className="card-body">
                          <ul className="list-unstyled text-sm mb-4 pricing-feature-list">
                            {(_package.features || []).map((feature) => {
                              return <li key={feature}>{feature}</li>;
                            })}
                          </ul>

                          <a
                            href={_package.fareharborURL}
                            className={
                              'btn mb-3 ' +
                              (_package.isFeatured
                                ? 'trip-solid-btn'
                                : 'trip-outline-btn')
                            }
                          >
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {!(this.props.hideFaq && this.props.hideFaq === true) &&
                this.props.theme !== 'four' && (
                  <div className="row mt-5">
                    <div className="col-lg-6">
                      <div id="accordion-1" className="accordion accordion-faq">
                        <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-1-1"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-1-1"
                            aria-expanded="false"
                            aria-controls="collapse-1-1"
                          >
                            <h6 className="mb-0">
                              <span className="ti-timer mr-3"></span>What month has the best weather in Las Vegas?
                            </h6>
                          </div>
                          <div
                            id="collapse-1-1"
                            className="collapse"
                            aria-labelledby="heading-1-1"
                            data-parent="#accordion-1"
                          >
                            <div className="card-body">
                              <p>
                                <strong>March, April, October, and November</strong> when the temperature in Las Vegas reaches 50° to 80° and is for outdoor activities. We don't recommend visiting Vegas in June and July as you can easily get overhitten while exploring outdoors.  
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-1-2"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-1-2"
                            aria-expanded="false"
                            aria-controls="collapse-1-2"
                          >
                            <h6 className="mb-0">
                              <span className="ti-home mr-3"></span>Where to stay during your trip to Nevada?
                            </h6>
                          </div>
                          <div
                            id="collapse-1-2"
                            className="collapse"
                            aria-labelledby="heading-1-2"
                            data-parent="#accordion-1"
                          >
                            <div className="card-body">
                              <p>
                                As a true explorer, you may want to stay in a tent, cabin, or even Teepee. Check out 
                               <a target='_blanc' className='intext-link' href="https://www.recreation.gov/discover/camping/Nevada/state"> recreation.gov</a> and 
                               <a target='_blanc' className='intext-link' href="https://www.hipcamp.com/en-US/d/united-states/nevada/camping/all"> hipcamp.com</a>.
                                <br />
                                P.S. Sometimes, you can contact hosts on Hipcamp directly and ask to get notified if the dates you are looking for are currently booked. 
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-1-3"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-1-3"
                            aria-expanded="false"
                            aria-controls="collapse-1-3"
                          >
                            <h6 className="mb-0">
                              <span className="ti-wallet mr-3"></span>How far in advance should I plan my weekend getaway in Nevada?
                            </h6>
                          </div>
                          <div
                            id="collapse-1-3"
                            className="collapse"
                            aria-labelledby="heading-1-3"
                            data-parent="#accordion-1"
                          >
                            <div className="card-body">
                              <p>
                                We recommend booking your Vegas adventure at least a few months in advance. Check for CDC guidelines for the State and National Parks you plan on visiting. A lot of outdoor activities are booked solid for the most popular months like March, April, October, and November. Make sure to book your activities in advance.
                              </p>
                            </div>
                          </div>
                      </div>
                   <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-1-4"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-1-4"
                            aria-expanded="false"
                            aria-controls="collapse-1-4"
                          >
                            <h6 className="mb-0">
                              <span className="ti-heart mr-3"></span>What are some date ideas for couples visiting Vegas?
                            </h6>
                          </div>
                          <div
                            id="collapse-1-4"
                            className="collapse"
                            aria-labelledby="heading-1-4"
                            data-parent="#accordion-1"
                          >
                            <div className="card-body">
                              <p>
                               Vegas has the most romantic outdoor date activities for couples. Here's our hand-crafted selection for yours and your loved one's next Vegas getaway.
                            <ul>
                            <li href="https://www.viator.com/tours/Las-Vegas/Red-Rock-Canyon-Self-Guided-Electric-Bike-Tour/d684-60124P1?pid=P00057350&mcid=42383&medium=link">Red E Bike Tours</li>
                            <li href="https://www.viator.com/tours/Las-Vegas/Red-Rock-Scooter-Tours/d684-14272P1?pid=P00057350&mcid=42383&medium=link">Red Rock Scooter Tours</li>
                            <li href="https://www.viator.com/tours/Las-Vegas/Kayak-Emerald-Cave-Express/d684-240046P1?pid=P00057350&mcid=42383&medium=link">Evolution Expeditions</li>
                            <li href="https://www.viator.com/tours/Sao-Paulo/2-Hour-Boat-and-Stand-Up-Paddle-Board-Adventure-in-Sao-Paulo-Brazil/d5112-14586P18?pid=P00057350&mcid=42383&medium=link">Blazin' Paddles</li>
                            <li href="https://www.viator.com/tours/Tulum/Cenote-Triple-Adventure-Tour-in-Tulum/d23012-15889P1?pid=P00057350&mcid=42383&medium=link">Grand Adventures Tours</li>
                            </ul>
                              </p>
                            </div>
                          </div>
                      </div>
                    <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-1-5"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-1-5"
                            aria-expanded="false"
                            aria-controls="collapse-1-5"
                          >
                            <h6 className="mb-0">
                              <span className="ti-money mr-3"></span>How to book a cheap flight to vegas?
                            </h6>
                          </div>
                          <div
                            id="collapse-1-5"
                            className="collapse"
                            aria-labelledby="heading-1-5"
                            data-parent="#accordion-1"
                          >
                            <div className="card-body">
                              <p>
                            You can go to Google Flights, select the dates you plan on flying to Vegas and toggle "track prices" option to get email notifications about the price changes for your preferred flight. Another option is to change your VPN location and see if prices for that region are cheaper than for your original region. 
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div id="accordion-2" className="accordion accordion-faq">
                        <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-2-1"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-2-1"
                            aria-expanded="false"
                            aria-controls="collapse-2-1"
                          >
                            <h6 className="mb-0">
                              <span className="ti-receipt mr-3"></span>How do I book everything last minute?
                            </h6>
                          </div>
                          <div
                            id="collapse-2-1"
                            className="collapse"
                            aria-labelledby="heading-2-1"
                            data-parent="#accordion-2"
                          >
                            <div className="card-body">
                              <p>
                               If you decide to visit Nevada on short notice, we can plan this adventure for you! Please fill out your Vegas Getaway Inquiry, and we will get back to you within 24 hrs of your request!
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-2-2"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-2-2"
                            aria-expanded="false"
                            aria-controls="collapse-2-2"
                          >
                            <h6 className="mb-0">
                              <span className="ti-eye mr-3"></span>What should I see in  Las Vegas?
                            </h6>
                          </div>
                          <div
                            id="collapse-2-2"
                            className="collapse"
                            aria-labelledby="heading-2-2"
                            data-parent="#accordion-2"
                          >
                            <div className="card-body">
                              <p>
                            Vegas is the party capital of the world, and you probably don't want to miss a bar crawl on Freemont Street, get wild at famous clubs on the Strip, attend the legendary Cirque Du Soleil, and visit the beautiful Hoover Dam!
                              </p>
                            </div>
                          </div>
                      </div>
                      <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-2-3"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-2-3"
                            aria-expanded="false"
                            aria-controls="collapse-2-3"
                          >
                            <h6 className="mb-0">
                              <span className="ti-car mr-3"></span>Can I visit Antelope Canyon and Horseshoe Bend from Las Vegas?
                            </h6>
                          </div>
                          <div
                            id="collapse-2-3"
                            className="collapse"
                            aria-labelledby="heading-2-3"
                            data-parent="#accordion-2"
                          >
                            <div className="card-body">
                              <p>
                            Yes, Antelope Canyon is 5 and a half hours away and Horseshoe Bend is only 4 hours away from Vegas. It is very convenient to visit both on the same trip. We also recommend <a href="/kayak-colorado-river">kayaking down the Colorado River</a> while at Lake Powel, AZ.
                              </p>
                            </div>
                          </div>
                      </div>
                      <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-2-4"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-2-4"
                            aria-expanded="false"
                            aria-controls="collapse-2-4"
                          >
                            <h6 className="mb-0">
                              <span className="ti-hand-drag mr-3"></span>Is Rock Climbing popular in Nevada?
                            </h6>
                          </div>
                          <div
                            id="collapse-2-4"
                            className="collapse"
                            aria-labelledby="heading-2-4"
                            data-parent="#accordion-2"
                          >
                            <div className="card-body">
                              <p>
                            Yes, the most popular areas for climbing in Nevada are Red Rock, Calico Basin, and Mount Charleston Wilderness Area. Check out Mary Jane Falls during winter months for ice climbing.
                              </p>
                            </div>
                          </div>
                      </div>
                      <div className="card">
                          <div
                            className="card-header py-4"
                            id="heading-2-5"
                            data-toggle="collapse"
                            role="button"
                            data-target="#collapse-2-5"
                            aria-expanded="false"
                            aria-controls="collapse-2-5"
                          >
                            <h6 className="mb-0">
                              <span className="ti-shine mr-3"></span>Is Las Vegas good for outdoorsy people?
                            </h6>
                          </div>
                          <div
                            id="collapse-2-5"
                            className="collapse"
                            aria-labelledby="heading-2-5"
                            data-parent="#accordion-2"
                          >
                            <div className="card-body">
                              <p>
                            Vegas has lost to offer for outdoorsy people. It is famous for hot springs, nearby National and State Parks, great spots for kayaking, rock climbing, hiking, biking, ATV, snowshoeing, and even snowboarding or skiing! If you identify yourself as outdoorsy and looking to visit Nevada, you can find lots of fun outdoorsy activities within a few hours drive from Las Vegas.
                              </p>
                            </div>
                          </div>
                      </div>
                      </div>
                    </div>
                  </div>
                )}

              {!(this.props.hideFaq && this.props.hideFaq === true) &&
                this.props.theme === 'four' && (
                  <div className="row pt-5">
                    <div className="col-md-6">
                      <div className="single-faq">
                        <h5>How can I pay for this?</h5>
                        <p>
                          Intrinsicly implement high standards in strategic
                          theme areas via inexpensive solutions. Assertively
                          conceptualize prospective bandwidth whereas
                          client-based imperatives.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="single-faq">
                        <h5>Is it possible to pay yearly?</h5>
                        <p>
                          Assertively iterate user friendly innovation without
                          open-source markets. Monotonectally extend resource
                          sucking manufactured products without high-payoff
                          paradigms. Objectively customize ubiquitous
                          information before economically sound relationships.{' '}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="single-faq">
                        <h5>Do you offer discounts on multiple items?</h5>
                        <p>
                          Dramatically target focused testing procedures after
                          holistic ideas. Collaboratively maximize high-payoff
                          ROI and value-added products. Distinctively deliver
                          cooperative collaboration and idea-sharing whereas
                          customized
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="single-faq">
                        <h5>Is VAT included in plan prices?</h5>
                        <p>
                          Distinctively simplify high-quality initiatives for
                          highly efficient applications. Monotonectally
                          repurpose integrated customer service after magnetic
                          e-services.{' '}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="single-faq">
                        <h5>Will I pay more for some features?</h5>
                        <p>
                          Enthusiastically pontificate resource-leveling supply
                          chains whereas scalable markets. Authoritatively
                          streamline resource maximizing methods of empowerment
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="single-faq">
                        <h5>
                          Why are there no limits on the number of messages?
                        </h5>
                        <p>
                          Assertively target turnkey ideas for market-driven
                          portals. Appropriately e-enable world-class
                          intellectual capital whereas 2.0 mindshare.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}
